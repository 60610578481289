import React from 'react'
import './aboutus.scss'

const AboutUs = () => {
    return (
        <div>
            {/* <h1>About Us</h1> */}
        </div>
    )
}


export default AboutUs