import React from 'react'
import DykkerUdstyr from '../components/Layout/DykkerUdstyr/DykkerUdstyr'

const Shop = () => {
    return (
        <div>
            <DykkerUdstyr />
        </div>
    )
}

export default Shop
