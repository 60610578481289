import React from 'react'
import DykkerKurser from '../components/Layout/DykkerKurser/DykkerKurser'

const Kurser = () => {
    return (
        <div>
            <DykkerKurser />
        </div>
    )
}

export default Kurser
