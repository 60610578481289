import React from 'react'
import FooterComp from '../components/Layout/FooterComp/FooterComp'

const Footer = () => {
    return (
        <div>
            <FooterComp />
        </div>
    )
}

export default Footer