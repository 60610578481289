import React from 'react'
import AboutUs from '../components/Layout/AboutUs/AboutUs'

const About = () => {
    return (
        <div>
            <AboutUs />
        </div>
    )
}

export default About