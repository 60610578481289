import React from 'react'
import DykkerRejser from '../components/Layout/DykkerRejser/DykkerRejser'

const Rejser = () => {
    return (
        <div>
            <DykkerRejser />
        </div>
    )
}

export default Rejser
