import React from 'react'
import './contactus.scss'

const ContactUs = () => {
    return (
        <div className="container">
            {/* <div className="row">
                <div className="col pt-5">
            <h1>Kontakt os</h1>
            <article className="row p-0 m-0 contactpage">
                <section className="col-12 col-sm-6 p-0 m-0 msgform">
                    <form className="msgformcomp">
                        <h2>Send en besked</h2>
                        <p>Navn:</p>
                        <input type="text" name="Navn" id="navn" required />
                        <p>Email:</p>
                        <input type="text" name="email" id="email" required />
                        <p>Telefonnr.:</p>
                        <input type="text" name="telefon" id="telefon" required />
                        <p>Besked:</p>
                        <textarea name="besked" id="besked"></textarea> <br /><br />
                        <button className="msgbutton" type="submit">Send besked</button>
                    </form>
                </section>
            </article>
            </div>
            </div> */}
        </div>

    )
}

export default ContactUs